import React, { useState } from 'react';
import { updateUserAttributes, getCurrentUser } from '@aws-amplify/auth';

const Settings = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleUpdate = async () => {
    try {
      const user = await getCurrentUser();
      const attributes = {
        email: email,
        phone_number: phoneNumber,
      };

      await updateUserAttributes(user, attributes);
      setMessage('User attributes updated successfully');
    } catch (err) {
      setMessage(`Error updating user attributes: ${err.message}`);
    }
  };

  return (
    <div>
      <h2>Update Settings</h2>
      <input
        type="email"
        placeholder="New Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <input
        type="tel"
        placeholder="New Phone Number"
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
      />
      <button onClick={handleUpdate}>Update</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default Settings;
