import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/HeroSection';   
import Login from './components/Login';
import PrivacyPolicy from './components/PrivacyPolicy';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Header from './components/Header';
import Footer from './components/Footer';
import Register from './components/Register'; 
import Forgot from './components/Forgot'; 
import { Amplify } from 'aws-amplify';
import config from './aws-exports';


Amplify.configure(config);

const App = () => (
  <BrowserRouter>
    <div className="app-container">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} /> 
        <Route path="/forgot" element={<Forgot />} /> 
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
      <Footer />
    </div>
  </BrowserRouter>
);

export default App;