import React from 'react';

const HeroSection = () => (
    <section className="hero">
        <h2>Welcome to IdeaHour</h2>
        <p>Your ultimate tool for generating business ideas and documents effortlessly.</p>
        <button>Get Started</button>
    </section>
);

export default HeroSection;