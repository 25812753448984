import React, { useState } from 'react';
import { signUp } from '@aws-amplify/auth'; // Import signUp directly
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');   

  const navigate = useNavigate();

  const   
 handleSignUp = async () => {
    try {
      // Use the imported signUp function directly
      await signUp({
        username,
        password,
        attributes: { email },
      });
      alert('Sign up successful! Please confirm your email.');
      navigate('/login');
    } catch (error) {
      console.log('Error signing up:', error);
      // Handle errors appropriately, e.g., display an error message to the user
    }
  };

  return (
    <div>
      <h2>Register</h2>
      <input value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
      <input value={email} onChange={(e) => setEmail(e.target.value)}   
 placeholder="Email" />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"   
 />
      <button onClick={handleSignUp}>Sign Up</button>
    </div>
  );
};

export default Register;