import React, { useEffect, useState } from 'react';
// eslint-disable-next-line 
import { useNavigate } from 'react-router-dom';
import { post } from 'aws-amplify/api';
import awsExports from '../aws-exports'; // Import aws-exports to access the API configurations

const callGPTApi = async (prompt) => {
  console.log('Calling GPT API with prompt:', prompt);

  // Log the API endpoint and name from aws-exports
  const gptApiConfig = awsExports.aws_cloud_logic_custom.find(api => api.name === 'GPTIntegration');
  if (!gptApiConfig) {
    console.error('API configuration for GPTIntegration not found in aws-exports.js');
    return;
  }
  console.log('Using GPT API endpoint:', gptApiConfig.endpoint);
  console.log('Using GPT API name:', gptApiConfig.name);
  console.log('Using GPT API stage:', gptApiConfig.stage);

  try {
    const response = await post('GPTIntegration', '/gpt', { 
      body: {
        prompt: prompt,
      },
    });
    console.log('GPT API Response:', response);
    return response;
  } catch (error) {
    console.error('Error calling GPT API:', error);
    throw error;
  }
};

const PrivacyPolicy = () => {
  const [gptResponse, setGptResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGptResponse = async () => {
      setIsLoading(true);
      try {
        // Call the Lambda function via Amplify API
        const response = await callGPTApi('What is the meaning of life?');
        setGptResponse(response.text); // Update with the response data from GPT
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGptResponse();
  }, []);

  return (
    <div>
      <h1>Privacy Policy</h1>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {!isLoading && gptResponse && <p>{gptResponse}</p>}
    </div>
  );
};

export default PrivacyPolicy;
