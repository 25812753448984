import React from 'react';
import { createRoot } from 'react-dom/client';  // Import createRoot from react-dom/client
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';

Amplify.configure(config);

// Get the root DOM element where your React app will be mounted
const container = document.getElementById('root');

// Use createRoot to initialize the root and render the App component
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
