import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signIn } from '@aws-amplify/auth'; 

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');   

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await   
 signIn(username, password); 
      navigate('/dashboard'); 
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleLogin}>   

        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}   

            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input   

            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>   

      <div className="links-container">
        <p>
          Forgot your password? <a href="/forgot">Reset it here</a>
        </p>
        <p>
          Don't have an account? <a href="/register">Sign up here</a>
        </p>
      </div>
    </div>
  );
};

export default Login;