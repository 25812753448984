
import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
    <header>
        <h1>IdeaHour</h1>
        <nav>
            <ul>
                <li><Link to="">Home</Link></li>
                <li><Link to="privacy-policy">Privacy Policy</Link></li>
                <li><Link to="login">Login</Link></li>
            </ul>
        </nav>
    </header>
);

export default Header;
