
import React from 'react';

const Footer = () => (
    <footer>
        <p>&copy; 2024 IdeaHour. All rights reserved.</p>
    </footer>
);

export default Footer;
