import React, { useState } from 'react';
import { resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const Forgot = () => {
  const [username, setUsername] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleResetPassword   
 = async () => {
    try {
      if (step === 1) {
        await resetPassword(username); 
        setStep(2);
      } else {
        await confirmResetPassword(username, code, newPassword); 
        alert('Password reset successful!');
        navigate('/login');
      }
    } catch (error) {
      console.log('Error resetting password:', error);
      // Handle errors appropriately, e.g., display an error message to the user
    }
  };

  return (
    <div>
      <h2>Forgot Password</h2>
      {step === 1 ? (
        <input 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          placeholder="Username" 
        />
      ) : (
        <>
          <input 
            value={code} 
            onChange={(e) => setCode(e.target.value)} 
            placeholder="Verification   
 Code" 
          />
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="New Password"
          />
        </>
      )}
      <button onClick={handleResetPassword}>
        {step === 1 ? 'Send Code' : 'Reset Password'}
      </button>
    </div>
  );
};

export default Forgot;